import { Form, Input, Typography } from 'antd';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect } from 'react';

import { FormContainer } from '../../../components/Container';
import FooterNavigator from '../../../components/FooterNavigator';
import { CLIENT_MEDICARE_CARD_DETAILS, DOCTORS_ROUTE } from '../../../constants/routes';
import {
  addAttachmentsData,
  addBookingQuestionAndAnswersData,
  selectBookingAttachmentsData,
  selectBookingQuestionAndAnswersData,
} from '../../../redux/slices/booking';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import UploadForm from '../../../components/UploadForm';
import { HealthSummaryQuestionMapping } from '../../../model';

const { Text } = Typography;
const { TextArea } = Input;

const DocumentsUploadingForm = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { healthSummary, medicalSupporting } = useSelector(selectBookingAttachmentsData);
  const { healthConcern, gpName } = useSelector(selectBookingQuestionAndAnswersData);

  const handleUploadHealthSummary = useCallback((attachment) => dispatch(addAttachmentsData({ healthSummary: attachment })), [dispatch]);
  const handleUploadMedicalSupporting = useCallback((attachment) => dispatch(addAttachmentsData({ medicalSupporting: attachment })), [dispatch]);

  useEffect(() => {
    form.setFieldsValue({ healthConcern, gpName });
  }, [form, healthConcern, gpName]);

  const submit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      dispatch(addBookingQuestionAndAnswersData(values));
      return true;
    } catch (e) {
      return false;
    }
  }, [dispatch, form]);

  return (
    <>
      <FormContainer layout="vertical" form={form}>
        <Form.Item
          label="If you have a referral or health summary from your GP please upload it here:"
        >
          <UploadForm uploadInfo={healthSummary} setUploadInfo={handleUploadHealthSummary} />
          If you do not have a valid GP referral,
          <Text strong>{' a Medicare rebate will not be available.'}</Text>
        </Form.Item>

        <Form.Item
          label="If you have any medical supporting documents ( i.e. pathology, imaging, reports etc) please upload here:"
        >
          <UploadForm uploadInfo={medicalSupporting} setUploadInfo={handleUploadMedicalSupporting} />
        </Form.Item>

        <Form.Item
          name="gpName"
          required
          rules={[
            {
              required: true,
              message: 'The answer can not be empty.',
            },
          ]}
          label={HealthSummaryQuestionMapping.gpName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="healthConcern"
          label={HealthSummaryQuestionMapping.healthConcern}
        >
          <TextArea rows={4} />
        </Form.Item>

        <FooterNavigator submit={submit} error="" next={CLIENT_MEDICARE_CARD_DETAILS} back={DOCTORS_ROUTE} containerStyle={{ marginTop: '50px' }} />
      </FormContainer>
    </>
  );
};

export default DocumentsUploadingForm;
