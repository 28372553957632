import React, {
  FC, useCallback, CSSProperties, useState,
} from 'react';
import { Alert, Button, Space } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

type Props = {
  back?: string;
  next?: string;
  containerStyle?: CSSProperties;
  submit?: () => Promise<boolean> | boolean;
  error?: string;
  loading?: boolean;
  hasNext?: boolean;
  hasBack?: boolean;
};

const FooterNavigator: FC<Props> = ({
  back,
  next,
  containerStyle = {},
  submit,
  error = 'Something went wrong',
  loading,
  hasNext = true,
  hasBack = true,
}) => {
  const [hasError, setHasError] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  const handleClickNext = useCallback(async () => {
    if (next) {
      if (submit) {
        const isValid = await submit();

        if (isValid) {
          setHasError(false);
          navigate(`${next}${search}`);
          return;
        }

        setHasError(true);
      } else {
        navigate(`${next}${search}`);
      }
    }
  }, [next, navigate, submit, search]);

  return (
    <Space direction="vertical" size={20} style={containerStyle}>
      {hasError && error && (
      <Alert
        message={error}
        type="error"
      />
      )}
      <Space size="large">
        {hasBack && back && (
          <Link to={`${back}${search}`}>
            <LeftOutlined />
            Back
          </Link>
        )}
        {hasNext && next && (
          <Button
            loading={loading || false}
            shape="round"
            onClick={handleClickNext}
            type="primary"
            htmlType="submit"
          >
            Next
          </Button>
        )}
      </Space>
    </Space>
  );
};

FooterNavigator.defaultProps = {
  containerStyle: {},
};

export default FooterNavigator;
