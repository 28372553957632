import styled from 'styled-components';

export const Logo = styled.img`
  display: block;
  margin: auto;
  margin-bottom: 45px;
`;

export const MainSection = styled.main`
  margin: auto auto 30px;
  width: 80%;
`;

export const TextSection = styled.section`
  padding: 10px 30px;
  font-size: 18px;
`;
