import {
  Alert, Button, Form, Space, Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContainerWithForm, FormContainer } from '../../components/Container';
import { HealthSummaryQuestionMapping, RequestStatus } from '../../model';
import { addBookingQuestionAndAnswersData, resetBookingData, selectBookingData } from '../../redux/slices/booking';
import { useAppDispatch } from '../../hooks/redux-hooks';
import { bookingAppointment } from '../../services/booking';
import { BookingConfirmPanel } from './BookingConfirmModal';
import { CLIENT_CREDIT_CARD_DETAILS, THANKS } from '../../constants/routes';
import { useGetSpecialistsQuery } from '../../services/specialists';
import { generateSpecialities } from '../../utils/specialist';
import analytics from '../../infra/analytics';
import {
  CONFIRM_BOOKING, PAGE_VIEW_EVENT, SITE_NAME,
} from '../../constants/analytics';

const Finish = () => {
  const bookingData = useSelector(selectBookingData);
  const appointment = bookingData?.appointment;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const [bookingStatus, setBookingStatus] = useState(RequestStatus.DEFAULT);
  const { data: specialists } = useGetSpecialistsQuery(bookingData.speciality?.id || '');
  const specialist = specialists?.find((_specialist) => _specialist.id === appointment?.specialistId);
  const [isRobot, setIsRobot] = useState(true);

  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: CONFIRM_BOOKING,
    });
  }, []);

  const handleRobotCheckboxChange = useCallback((value) => setIsRobot(!value), [setIsRobot]);

  const handleSubmit = useCallback(async (): Promise<RequestStatus> => {
    setBookingStatus(RequestStatus.LOADING);
    let values = {};
    const bookingDataBody = { ...bookingData };
    try {
      values = await form.validateFields();
      const newQuestionAndAnswersData = { ...values, consultWay: 'video' };
      dispatch(addBookingQuestionAndAnswersData(newQuestionAndAnswersData));
      bookingDataBody.questionAndAnswers = { ...bookingData.questionAndAnswers, ...newQuestionAndAnswersData };
      const { status } = await bookingAppointment(bookingDataBody);
      setBookingStatus(status);
      return status;
    } catch (error) {
      setBookingStatus(RequestStatus.FAILURE);
      return RequestStatus.FAILURE;
    }
  }, [dispatch, form, bookingData]);

  return (
    <ContainerWithForm>
      <BookingConfirmPanel
        bookingSummary={{
          specialist: {
            avatarURL: specialist?.avatarLink || '',
            title: specialist?.title || '',
            displayName: specialist ? `${specialist.firstName} ${specialist.lastName}` : '',
            specialityInfo: generateSpecialities(specialist?.specialities),
          },
          appointment: {
            startDateTime: `${
              moment(appointment?.date, 'YYYY-MM-DD').format('dddd, DD MMMM')
            } at ${
              moment(appointment?.startTime, 'hh:mm').format('hh:mm A')
            }`,
          },
        }}
      />
      <ReCAPTCHA
        // @ts-ignore
        style={{ width: '300px', margin: '30px auto' }}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
        onChange={handleRobotCheckboxChange}
      />

      <div style={{ width: '300px', margin: '30px auto' }}>
        <FormContainer layout="vertical" form={form} style={{ marginBottom: '40px' }}>
          <Form.Item
            label={HealthSummaryQuestionMapping.howHear}
            name="howHear"
          >
            <TextArea />
          </Form.Item>
        </FormContainer>
        <Typography.Title level={5} style={{ textAlign: 'left' }}>
          If you need to reach us ahead of your consultation or if you have any questions, please email
          <a href="mailto:admin@telecareonline.com.au"> admin@telecareonline.com.au </a>
          or
          <a href="tel:0390524872"> phone (03) 9052 4872.</a>
        </Typography.Title>
      </div>

      <Space size="large" style={{ marginTop: '50px' }}>
        <Link to={`${CLIENT_CREDIT_CARD_DETAILS}${search}`}>
          <LeftOutlined />
          Back
        </Link>
        <Button
          disabled={bookingStatus === RequestStatus.SUCCESS || isRobot}
          shape="round"
          type="primary"
          onClick={async () => {
            const status = await handleSubmit();
            if (status === RequestStatus.SUCCESS) {
              navigate(THANKS);
              dispatch(resetBookingData());
            }
          }}
          loading={bookingStatus === RequestStatus.LOADING}
        >
          Book
        </Button>
      </Space>
      {
        [RequestStatus.FAILURE, RequestStatus.SUCCESS].includes(bookingStatus) && (
        <Alert
          style={{ marginTop: '20px' }}
          message={`Booking ${bookingStatus === RequestStatus.SUCCESS ? 'success' : 'failure'}`}
          type={bookingStatus === RequestStatus.SUCCESS ? 'success' : 'error'}
        />
        )
      }
    </ContainerWithForm>
  );
};

export default Finish;
