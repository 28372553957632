import { Typography } from 'antd';
import React, { FC, useEffect } from 'react';
import { ContainerWithForm } from '../../components/Container';
import MedicareForm from './MedicareCardForm';
import analytics from '../../infra/analytics';
import { MEDICARE_DETAILS, PAGE_VIEW_EVENT, SITE_NAME } from '../../constants/analytics';

const MedicareDetails: FC = () => {
  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: MEDICARE_DETAILS,
    });
  }, []);

  return (
    <ContainerWithForm>
      <Typography.Title
        level={2}
        style={{ marginBottom: '50px' }}
      >
        Medicare Details
      </Typography.Title>

      <MedicareForm />
    </ContainerWithForm>
  );
};

export default MedicareDetails;
