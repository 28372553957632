/* eslint-disable no-unused-vars */
export interface Attachment {
  fileId: string; // 83587
  fileName: string; // "referral letter"
}

export enum UPLOAD_STATUS {
  DONE='done',
  UPLOADING='uploading',
  SUCCESS='success',
  REMOVED='removed',
  ERROR='error',
  DEFAULT='default',
}

export interface AttachmentWithStatus extends Attachment {
  status: UPLOAD_STATUS
}
