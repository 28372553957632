import Text from 'antd/es/typography/Text';
import React, { FC, ReactElement } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';
import Checkbox from 'antd/es/checkbox/Checkbox';

const CheckboxContainer = styled(Checkbox)`
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;

  .ant-checkbox {
    transform: scale(1.625);
    margin-right: 10px;
  }
`;

// eslint-disable-next-line no-unused-vars
const AgreeCheck: FC<{text?: string | string[] | ReactElement, isAgreed: boolean, setIsAgreed: (isAgreed: boolean) => void}> = ({ text, isAgreed, setIsAgreed }) => (
  <Space direction="vertical" size={30} style={{ width: '100%' }}>
    {text && (
    <Text style={{ fontSize: '18px', display: 'inline-block', textAlign: 'left' }}>
      { Array.isArray(text)
        ? text.map((content: string) => (
          <span key={content}>
            {content}
            <br />
          </span>
        ))
        : text}
    </Text>
    )}
    <CheckboxContainer
      checked={isAgreed}
      onChange={(e) => setIsAgreed(e.target.checked)}
      style={{ fontSize: '18px' }}
    >
      Yes, I agree
    </CheckboxContainer>
  </Space>
);

export default AgreeCheck;
