export const PAGE_VIEW_EVENT = 'page_view';
export const SITE_NAME = 'website-booking';

export const SELECT_SPECIALIST = 'select-specialist';
export const SELECT_SPECIALTY = 'select-specialty';
export const NEW_PATIENT_REGISTRATION = 'new-patient-registration';
export const UPLOAD_REFERRAL = 'upload-referral';
export const MEDICARE_DETAILS = 'medicare-details';
export const CREDIT_CARD_AND_POLICY = 'credit-card-and-policy';
export const CONFIRM_BOOKING = 'confirm-booking';
export const BOOKING_RESULT = 'booking-result';
