import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

class FirebaseService {
  analytics;

  constructor() {
    firebase.initializeApp(config);
    this.analytics = firebase.analytics();
  }
}

const firebaseService = new FirebaseService();

export default firebaseService;
