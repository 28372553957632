export const ROOT_ROUTE = '';
export const DOCTORS_ROUTE = `${ROOT_ROUTE}/doctors`;
export const SPECIALITIES_ROUTE = `${ROOT_ROUTE}/specialities`;
export const CLIENT_DETAILS = `${ROOT_ROUTE}/client-details`;
export const CLIENT_MEDICARE_CARD_DETAILS = `${ROOT_ROUTE}/client-medicare-card-details`;
export const CLIENT_CREDIT_CARD_DETAILS = `${ROOT_ROUTE}/client-credit-card-details`;
export const DOCUMENTS_UPLOADING = `${ROOT_ROUTE}/health-summary`;
export const FINISH = `${ROOT_ROUTE}/finish`;
export const THANKS = `${ROOT_ROUTE}/thanks`;

export const STANDALONE_PATHS = [CLIENT_DETAILS, `${CLIENT_DETAILS}/`, THANKS];
