import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ClientAppointment } from '../model';

export const clientAppointmentApi = createApi({
  reducerPath: 'clientAppointmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/client-appointments`,
    prepareHeaders: (headers) => {
      headers.set('X-API-KEY', process.env.API_KEY as string);
      headers.set('Site-Id', process.env.REACT_APP_CURRENT_SITE_ID as string);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getClientAppointments: builder.query<ClientAppointment, string>({
      query: () => 'client-appointments/',
    }),
  }),
});

export const { useGetClientAppointmentsQuery } = clientAppointmentApi;
