import { isEmpty } from 'lodash';

export const removeEmptyStrFields = (obj: any): any => Object.entries(obj).reduce((result: any, item: [string, any]) => {
  const [key, value] = item;

  if (!isEmpty(value)) {
    return { ...result, [key]: value };
  }

  return result;
}, {});
