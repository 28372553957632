import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import BookingHome from '../pages/BookingHome';
import Specialists from '../pages/Specialists';
import NotFound from './NotFound';
import {
  CLIENT_CREDIT_CARD_DETAILS,
  CLIENT_DETAILS,
  CLIENT_MEDICARE_CARD_DETAILS,
  DOCTORS_ROUTE,
  DOCUMENTS_UPLOADING,
  FINISH,
  ROOT_ROUTE,
  SPECIALITIES_ROUTE,
  THANKS,
} from '../constants/routes';
import ClientDetails from '../pages/ClientDetails';
import MedicareDetails from '../pages/MedicareDetails';
import DocumentsUploading from '../pages/DocumentsUploading';
import Finish from '../pages/Finish';
import Thanks from '../pages/Thanks';
import CreditCardDetails from '../pages/CreditCardDetails';
import Specialities from '../pages/Specialities';

const RouterConfig: FC = () => (
  <Routes>
    <Route path={ROOT_ROUTE} element={<BookingHome />}>
      <Route path={SPECIALITIES_ROUTE} element={<Specialities />} />
      <Route path={DOCTORS_ROUTE} element={<Specialists />} />
      <Route path={CLIENT_DETAILS} element={<ClientDetails />} />
      <Route path={DOCUMENTS_UPLOADING} element={<DocumentsUploading />} />
      <Route path={CLIENT_MEDICARE_CARD_DETAILS} element={<MedicareDetails />} />
      <Route path={CLIENT_CREDIT_CARD_DETAILS} element={<CreditCardDetails />} />
      <Route path={FINISH} element={<Finish />} />
      <Route path={THANKS} element={<Thanks />} />
    </Route>
    <Route path="*" element={<BookingHome><NotFound /></BookingHome>} />
  </Routes>
);

export default RouterConfig;
