export const STATES = [
  {
    label: 'Australian Capital Territory',
    value: 'ACT',
  },
  {
    label: 'New South Wales',
    value: 'NSW',
  },
  {
    label: 'Northern Territory',
    value: 'NT',
  },
  {
    label: 'Queensland',
    value: 'QLD',
  },
  {
    label: 'South Australia',
    value: 'SA',
  },
  {
    label: 'Tasmania',
    value: 'TAS',
  },
  {
    label: 'Victoria',
    value: 'VIC',
  },
  {
    label: 'Western Australia',
    value: 'WA',
  },
];
