export const generateValidMobileOrPhoneNumber = (number: string) => {
  const digitalNumber = number.trim().replace(/\D/g, '');
  const withoutStartNumber = digitalNumber.replace(/^(0|61)/g, '');

  if (/^4\d{8}$/.test(withoutStartNumber)) {
    return {
      mobile: `+61${withoutStartNumber}`,
    };
  }

  if (/^\d{9}$/.test(withoutStartNumber)) {
    return {
      phoneNumber: `+61${withoutStartNumber}`,
    };
  }

  return {};
};

export const trimPhoneNumber = (phone: string = '') => phone.trim();
export const mobileRegxPattern = /^(0|\+61 ?)4[0-9]{8}$/;
// export const phoneNumberRegxPattern = /^(0|\+61 ?)[^\D4][0-9]{8}$/;
