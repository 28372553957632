export const MONTH = 'YYYYMM';
export const DATE = 'YYYYMMDD';
export const DATE_WITH_DASH = 'YYYY-MM-DD';
export const DAY = 'DD';
export const FULL_DATE = 'ddd DD-MMM-YYYY';
export const WEEK_MONTH = 'dddd, Do MMMM';
export const HOUR = 'HH:mm';
export const HOUR_WITH_AM_PM = 'HH:mm A';
export const DATE_AND_TIME = 'YYYYMMDDHH:mm';
export const ISO = 'YYYY-MM-DDTHH:mm:ss Z';
export const FULL_TIME = 'YYYY-MM-DDTHH:mm:ss';
export const TABLE_TIME = 'DD/MM/YYYY';
export const CALENDAR = 'MMMM YYYY';
export const DATE_WITH_DASH_WITH_TIME = 'YYYY-MM-DDHH:mm';
