import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment-timezone';
import AvailableTime from '../model/AvailableTime';
import { AppointmentType } from '../model';

interface GetAvailabilitiesBySpecialistQuery {
  month: string;
  specialistId: string;
  appointmentType: AppointmentType;
}

export const availabilitiesApi = createApi({
  reducerPath: 'availabilitiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/availabilities`,
    prepareHeaders: (headers) => {
      headers.set('X-API-KEY', process.env.REACT_APP_API_KEY as string);
      headers.set('Site-Id', process.env.REACT_APP_CURRENT_SITE_ID as string);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAvailabilitiesBySpecialist: builder.query<AvailableTime[], GetAvailabilitiesBySpecialistQuery>({
      query: (query) => {
        const params = new URLSearchParams({ ...query, timezone: moment.tz.guess() });
        return `?${params.toString()}`;
      },
    }),
  }),
});

export const {
  useGetAvailabilitiesBySpecialistQuery,
} = availabilitiesApi;
