import { Typography, Row } from 'antd';
import React, { FC } from 'react';
import AgreeCheck from '../../../components/AgreeCheck';

interface ConsentsProps {
  isAgreedThePrivacyPolicy: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsAgreedThePrivacyPolicy: (value: boolean) => void;
}

const Consents: FC<ConsentsProps> = ({
  isAgreedThePrivacyPolicy, setIsAgreedThePrivacyPolicy,
}) => (
  <div style={{ marginBottom: '50px' }}>
    <Typography.Title
      level={3}
      style={{ marginBottom: '20px', textAlign: 'center' }}
    >
      Privacy Policy
    </Typography.Title>

    <Row>
      <Typography.Text style={{ marginBottom: '10px', textAlign: 'left', fontSize: '18px' }}>
        I have read Telecare&apos;s
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.telecare.com.au/privacy-policy"
        >
          {' Privacy Policy '}
        </a>
        and I understand that the information submitted in this form will be accessed by:
        <ul>
          <li>Telecare&apos;s practice management and reception team</li>
          <li>Telecare&apos;s team of clinicians to ensure I receive optimal care</li>
        </ul>
      </Typography.Text>
      <AgreeCheck
        isAgreed={isAgreedThePrivacyPolicy}
        setIsAgreed={setIsAgreedThePrivacyPolicy}
      />
    </Row>

  </div>
);

export default Consents;
