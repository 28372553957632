import { Row, Typography } from 'antd';
import React, { FC } from 'react';
import { TableOutlined } from '@ant-design/icons';
import Icon from '../Icon';
import { TABLE_ICON } from '../../styles/color';

interface IconMap {
  [key: string]: JSX.Element
}

const iconMapping: IconMap = {
  table: (
    <Icon
      Icon={TableOutlined}
      iconBgColor={TABLE_ICON.BG}
      iconColor={TABLE_ICON.COLOR}
    />
  ),
};

const TitleWithIcon: FC<{icon: string, text: string}> = ({ icon, text }) => (
  <Row style={{ lineHeight: '32px', marginBottom: '16px' }}>
    {iconMapping[icon]}
    <Typography.Title
      level={4}
      style={{
        fontSize: '18px',
        fontWeight: 800,
        margin: '0',
        lineHeight: '32px',
      }}
    >
      {text}
    </Typography.Title>
  </Row>
);

export default TitleWithIcon;
