import { Typography } from 'antd';
import React, { useEffect } from 'react';

import {
  Container, TextSection, Logo, MainSection,
} from './styles';
import analytics from '../../infra/analytics';
import { BOOKING_RESULT, PAGE_VIEW_EVENT, SITE_NAME } from '../../constants/analytics';

const { Title } = Typography;

const Thanks = () => {
  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      booking_result: 'success',
      page_name: BOOKING_RESULT,
    });
  }, []);

  return (
    <Container>
      <header>
        <Logo alt="logo" width="70%" height="68px" src="/telecare-logo.svg" />
      </header>

      <Typography.Title
        level={2}
        style={{
          marginBottom: '70px', fontWeight: 800,
        }}
      >
        Confirmation
      </Typography.Title>

      <MainSection>
        <Typography.Title
          level={3}
          style={{
            marginBottom: '70px', fontWeight: 800,
          }}
        >
          Thank you for taking the time to provide us with your details. We look forward to helping you.
        </Typography.Title>
        <img alt="telecare" width="100%" height="242px" src="/doctors.svg" />
      </MainSection>

      <TextSection>
        <Title level={4}>
          You will receive an email and SMS confirming your appointment shortly.
        </Title>

        <Title level={4}>
          If you are having problems or need to reach us ahead of your consultation or if you have any questions, please email
          <a style={{ textDecoration: 'underline' }} href="mailto:admin@telecareonline.com.au">{' admin@telecareonline.com.au '}</a>
          or call us on
          <a style={{ textDecoration: 'underline' }} href="tel:(03)90524872">{' (03) 9052 4872 '}</a>
          .
        </Title>

        <Typography.Text style={{ marginTop: '50px', display: 'block', textDecoration: 'underline' }}><a href="https://www.telecare.com.au/">Back to Home</a></Typography.Text>
      </TextSection>
    </Container>
  );
};

export default Thanks;
