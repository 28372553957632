import styled from 'styled-components';
import { Alert, Button } from 'antd';
import { WHITE_BG } from '../../styles/color';
import { UploadAlertProp } from './index';

export const UploadFormContainer = styled.div`
  width: 852px;
  height: 258px;
  background: ${WHITE_BG};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 30px 20px;
  margin: 0 auto;
`;

export const UploadButton = styled(Button)`
  border: 1px solid #bfbfbf;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
`;

export const UploadAlert = styled(Alert)<UploadAlertProp>`
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  box-sizing: border-box;
  border-radius: 40px;
  padding: 7px 17px;

  .ant-alert-content {
    display: flex;
  }

  .ant-alert-message {
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    margin-right: 8px;
    margin-bottom: 0;
  }

  .ant-alert-description {
    font-size: 12px;
    line-height: 24px;
  }
`;
