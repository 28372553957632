import { Speciality } from '../model/Speciality';
import request from '../infra/request';
import { RequestStatus } from '../model';

const { SUCCESS, FAILURE } = RequestStatus;

export const getSpecialities = async (): Promise<{ status: RequestStatus, specialities?: Speciality[], error?: Error }> => {
  try {
    const { status, data: specialities } = await request('/specialities');

    if (status === 200) {
      return {
        status: SUCCESS,
        specialities,
      };
    }

    return {
      status: FAILURE,
      error: new Error('Failed to get all specialities'),
    };
  } catch (error) {
    return {
      status: FAILURE,
      error: new Error(`${error}`),
    };
  }
};
