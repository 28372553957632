import styled from 'styled-components';
import { Button, Card } from 'antd';
import { MAIN_COLOR } from '../../../styles/color';

export const StyledCard = styled(Card)`
  border-radius: 20px;
  padding: 27px 16px;
  margin-bottom: 24px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-card-head-title {
    text-align: center;
    padding: 0;
  }
  .ant-card-head {
    font-weight: 700;
    border-bottom: none;
    min-height: 1px;
  }
  .ant-card-body {
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledPrimaryButton = styled(Button)`
  color: white;
  border-radius: 16px;
  background-color: ${MAIN_COLOR};
  border-color: ${MAIN_COLOR};

  & :hover {
    color: ${MAIN_COLOR};
  }
`;
