import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { clientAppointmentApi } from '../services/client-appointments';
import bookingDataReducer from './slices/booking';
import { specialistsApi } from '../services/specialists';
import { availabilitiesApi } from '../services/availabilities';

export const store = configureStore({
  reducer: {
    [clientAppointmentApi.reducerPath]: clientAppointmentApi.reducer,
    [specialistsApi.reducerPath]: specialistsApi.reducer,
    [availabilitiesApi.reducerPath]: availabilitiesApi.reducer,
    bookingData: bookingDataReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(clientAppointmentApi.middleware)
    .concat(specialistsApi.middleware)
    .concat(availabilitiesApi.middleware),
  devTools: process.env.REACT_APP_ENVIRONMENT === 'local',
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
