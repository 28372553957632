import React, { useEffect } from 'react';
import {
  Col, Form, FormInstance, Input, Row,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  createTokenCustomer,
} from '../../../services/payment';
import { selectBookingClientData } from '../../../redux/slices/booking';

interface EwayPaymentFormProps {
  form: FormInstance,
  // eslint-disable-next-line no-unused-vars
  setEWayAccessCode: (value: string) => void;
  // eslint-disable-next-line no-unused-vars
  setEWayFormActionURL: (value: string) => void;
}

const EwayPaymentForm = ({ form, setEWayAccessCode, setEWayFormActionURL }: EwayPaymentFormProps) => {
  const patientData = useSelector(selectBookingClientData);

  useEffect(() => {
    (async () => {
      const { data, error } = await createTokenCustomer({
        firstName: patientData.firstName || '',
        lastName: patientData.lastName || '',
        email: patientData.email || '',
        mobile: patientData.mobile || '',
        redirectUrl: 'https://www.google.com',
      });
      if (error || !data) {
        return;
      }
      const { accessCode, formActionURL } = data;
      setEWayAccessCode(accessCode);
      setEWayFormActionURL(formActionURL);
    })();
  }, [patientData, setEWayAccessCode, setEWayFormActionURL]);

  return (
    <>
      <Form
        form={form}
        id="payment_form"
        layout="vertical"
        method="POST"
        style={{ textAlign: 'start' }}
      >
        <Form.Item
          required
          label="Cardholder’s Name"
          name="EWAY_CARDNAME"
          rules={[{
            required: true,
            transform: (value) => value?.trim(),
            message: 'Please input the card holder listed on your credit card.',
          }]}
        >
          <Input required placeholder="Name on card" name="EWAY_CARDNAME" />
        </Form.Item>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              required
              label="Credit Card Number (no spaces)"
              name="EWAY_CARDNUMBER"
              rules={[
                {
                  required: true,
                  message: 'Please input your credit card number.',
                },
                {
                  pattern: /^(\d{16})$/,
                  message: 'Please input a valid credit card number.',
                },
              ]}
            >
              <Input required placeholder="**** **** **** ****" name="EWAY_CARDNUMBER" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              required
              label="Expiry Date"
              name="EWAY_CARDEXPIRYDATE"
              rules={[{
                required: true,
                message: 'Please input your credit card\'s expiry date.',
              }, {
                pattern: /^0[1-9]|1[0-2]\/(19|20)\d{2}$/,
                message: 'Please input a valid date.',
              }]}
            >
              <Input required placeholder="MM/YYYY" name="EWAY_CARDEXPIRYDATE" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          required
          label="CVC"
          name="EWAY_CARDCVN"
          rules={[{
            required: true,
            message: 'Please input your credit card\'s CVC.',
          }, {
            pattern: /^(\d{3}|\d{4})$/,
            message: 'Please input a valid CVC.',
          }]}
        >
          <Input required placeholder="***" name="EWAY_CARDCVN" />
        </Form.Item>
      </Form>
    </>
  );
};

export default EwayPaymentForm;
