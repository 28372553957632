import React, { FC } from 'react';
import {
  Col, Row, Typography,
} from 'antd';
import { Speciality } from '../../../model/Speciality';
import SpecialityProfileCard from '../SpecialityProfileCard';
import SpecialitySearchBox from '../SpecialitySearchBox';

type Props = {
  specialities: Speciality[]
}

const { Title } = Typography;

const SpecialitiesGrid: FC<Props> = ({ specialities }) => {
  if (specialities.length < 1) {
    return (
      <Title level={4} style={{ color: 'grey' }}>No available speciality</Title>
    );
  }
  return (
    <>
      <SpecialitySearchBox specialities={specialities} />
      <Row
        gutter={{
          xs: 8, sm: 16, md: 24, lg: 32,
        }}
        style={{ padding: '0 40px' }}
      >
        {specialities.map((speciality) => (
          <Col className="gutter-row" xs={24} md={12} lg={8} xl={6} key={speciality.code}>
            <SpecialityProfileCard speciality={speciality} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SpecialitiesGrid;
