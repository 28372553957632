import styled from 'styled-components';
import Form from 'antd/es/form/Form';
import { DEVICES } from '../../styles/breakpoint';

export const ContainerWithForm = styled.div`
  text-align: center;
  margin: 20px 10px;
  width: 95%;
  
 ${DEVICES.desktop} {
  width: 50%;
  margin: 50px auto 100px;
 }
`;

export const Container = styled.div<{width?: string, textAlign?: string}>`
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
  margin: 50px auto 100px;
  width: ${({ width }) => width ?? '100%'}
`;

export const FormContainer = styled(Form)`
  margin-bottom: 150px;
  .ant-form-item-label > label {
    font-weight: 500;
  }
  
  & .ant-form-item {
    text-Align: left;
  }
`;
