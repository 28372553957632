import styled from 'styled-components';
import React, { FC } from 'react';

type Props = {Icon: any, iconColor: string, iconBgColor: string};
type StyledProp = Pick<Props, 'iconColor' | 'iconBgColor'>

const IconContainer = styled.div<StyledProp>`
  border-radius: 10px;
  font-size: 12px;
  color: ${({ iconColor }) => iconColor};
  width: 32px;
  height: 32px;
  vertical-align: middle;
  background: ${({ iconBgColor }) => iconBgColor};
  margin-right: 16px;
`;

const IconComponent: FC<Props> = ({ Icon, iconColor, iconBgColor }) => (
  <IconContainer iconColor={iconColor} iconBgColor={iconBgColor}>
    <Icon
      style={{
        display: 'block',
        margin: '50% auto',
        transform: 'translateY(-6px)',
      }}
    />
  </IconContainer>
);

export default IconComponent;
