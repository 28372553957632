import { Avatar, Typography } from 'antd';
import React, { FC } from 'react';

type BookingSummary = {
  specialist: {
    title: string;
    avatarURL: string;
    displayName: string;
    specialityInfo: string;
  }
  appointment: {
    startDateTime: string;
  }
}

type BookingConfirmPanelProps = {
  bookingSummary: BookingSummary,
}

type BookingSummaryPanelProps = {
  bookingSummary: BookingSummary,
}

const BookingSummaryPanel: FC<BookingSummaryPanelProps> = ({ bookingSummary }) => {
  const {
    specialist: {
      avatarURL, displayName, specialityInfo, title,
    },
    appointment: { startDateTime },
  } = bookingSummary;
  return (
    <div style={{ textAlign: 'center', marginTop: '30px' }}>
      <Avatar src={avatarURL} size={150} style={{ marginBottom: '30px' }} />
      <Typography.Title level={3}>{`${title} ${displayName}`.trim()}</Typography.Title>
      <Typography.Paragraph style={{ fontSize: '14px' }}>{specialityInfo}</Typography.Paragraph>
      <Typography.Paragraph strong style={{ fontSize: '16px' }}>{startDateTime}</Typography.Paragraph>
    </div>
  );
};

export const BookingConfirmPanel: FC<BookingConfirmPanelProps> = ({
  bookingSummary,
}) => (
  <>
    <Typography.Title level={2}>
      Confirm your Booking
    </Typography.Title>
    <Typography.Text>
      Please confirm the following booking information.
    </Typography.Text>
    <BookingSummaryPanel bookingSummary={bookingSummary} />
  </>
);
