// TODO booking api
import { isNil } from 'lodash';
import moment from 'moment';
import {
  AppointmentData,
  BookingData,
  BookingDataState,
  ClientData,
  Consents,
  HealthSummaryQuestionMapping,
  HttpResponse,
  Map, QuestionAndAnswer,
  QuestionAndAnswers,
  RequestStatus,
  RequiredAddress,
} from '../model';
import { IDVA, MedicareCard } from '../model/MedicareCard';
import { DATE_WITH_DASH_WITH_TIME } from '../constants/time-formats';
import request from '../infra/request';

const validateBookingData = (bookingData: BookingDataState): boolean => {
  const {
    appointment, client, medicareCard, questionAndAnswers, consents = {}, ewayPayment,
  } = bookingData;
  if (isNil(appointment) || isNil(client) || isNil(medicareCard) || isNil(questionAndAnswers)) {
    return false;
  }

  const {
    specialistId, type, date, startTime, aptLength,
  } = appointment as AppointmentData;
  if (isNil(specialistId) || isNil(type) || isNil(date) || isNil(startTime) || isNil(aptLength)) {
    return false;
  }

  const {
    firstName, lastName, dateOfBirth, mobile, email, addressResidential,
  } = client as ClientData;

  const {
    streetAddress, suburb, state, postcode,
  } = addressResidential as RequiredAddress;

  if (isNil(firstName) || isNil(lastName) || isNil(dateOfBirth)
    || isNil(mobile) || isNil(email) || isNil(streetAddress)
    || isNil(suburb) || isNil(state) || isNil(postcode)
  ) {
    return false;
  }

  const {
    medicareCardNumber, medicareCardIRN, expiry, dva,
  } = medicareCard as MedicareCard;
  if (dva?.hasDept) {
    if (isNil(dva)) {
      return false;
    }
    const { hasDept, cardType, cardNumber } = dva;
    if (isNil(hasDept) || isNil(cardType) || isNil(cardNumber)) {
      return false;
    }
  }

  if (isNil(medicareCardNumber) || isNil(medicareCardIRN) || isNil(expiry)) {
    return false;
  }

  const { consultWay, gpName } = questionAndAnswers as QuestionAndAnswers;
  if (isNil(consultWay) || isNil(gpName)) {
    return false;
  }

  if (isNil(ewayPayment?.customerId)) {
    return false;
  }

  const {
    isAgreedTheFees, isAgreedTheCancelPolicy,
  } = consents as Consents;
  return !(isNil(isAgreedTheFees) || isNil(isAgreedTheCancelPolicy));
};

const generateNotes = (originalNotes = '', questions: QuestionAndAnswer[], dva: IDVA, eWayCustomerId: string) => {
  const notesWithQuestions = questions.reduce((notes, { question, answer }) => {
    if (answer) {
      return `${notes ? `${notes}\n` : ''}${question}:${answer}`;
    }

    return notes;
  }, originalNotes);

  const dvaSummary = `DVA No.: ${dva?.cardNumber}\nDVA Card Type: ${dva?.cardType}`;

  return `Eway Payment is ready(Eway customer ID: ${eWayCustomerId})\n${notesWithQuestions}\n${dvaSummary}`;
};

const transformBookingData = (bookingData: BookingData) => {
  const data: any = {};
  const {
    appointment, client, medicareCard, questionAndAnswers, consents, attachments, ewayPayment,
  } = bookingData;
  const {
    type, date, startTime, aptLength, specialistId,
  } = appointment as AppointmentData;
  const startDateMoment = moment(`${date}${startTime}`, DATE_WITH_DASH_WITH_TIME);

  data.appointment = {
    appointmentType: type,
    startDateTime: startDateMoment.format(),
    endDateTime: startDateMoment.clone().add(aptLength, 'minutes').format(),
  };
  data.specialist = { specialistId };

  data.healthSummary = {
    questionAndAnswers: Object.keys(questionAndAnswers).map((key) => ({
      question: (HealthSummaryQuestionMapping as Map)[key],
      // @ts-ignore
      answer: questionAndAnswers[key],
    })),
  };

  data.client = {
    ...client,
    medicareCard,
    ewayPayment: {
      customerId: ewayPayment.customerId,
    },
    notes1: generateNotes(client.notes1, data.healthSummary.questionAndAnswers, medicareCard.dva, ewayPayment.customerId),
  };

  if (attachments) {
    data.healthSummary.attachments = Object.values(attachments).map(({ fileId, fileName }) => ({ fileId, fileName }));
  }

  data.consents = Object.values(consents || {});

  data.metadata = {
    bookingFlowType: 'specialist',
  };

  return data;
};

export const bookingAppointment = async (bookingData: BookingDataState): Promise<HttpResponse<any>> => {
  const isValid = validateBookingData(bookingData);
  if (isValid) {
    const formattedBookingData = transformBookingData(bookingData as BookingData);
    try {
      const { status } = await request.post<any>('/bookings', formattedBookingData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY || '',
        },
      });

      if (status === 201) {
        return { status: RequestStatus.SUCCESS };
      }

      return { status: RequestStatus.FAILURE, error: 'Booking failed' };
    } catch (error) {
      return { status: RequestStatus.FAILURE, error: error as string };
    }
  }

  return { status: RequestStatus.FAILURE, error: 'Request validation failed' };
};
