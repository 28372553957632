import { Space, Typography } from 'antd';
import React, { FC, useEffect } from 'react';
import { ContainerWithForm } from '../../components/Container';
import DocumentsUploadingForm from './DocumentsUploadingForm';
import analytics from '../../infra/analytics';
import {
  PAGE_VIEW_EVENT, SITE_NAME, UPLOAD_REFERRAL,
} from '../../constants/analytics';

const HealthSummary: FC = () => {
  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: UPLOAD_REFERRAL,
    });
  }, []);

  return (
    <ContainerWithForm>
      <Space direction="vertical" size={50}>
        <Typography.Title
          level={2}
        >
          Upload Patient Referral / Supporting Documents
        </Typography.Title>

        <DocumentsUploadingForm />
      </Space>
    </ContainerWithForm>
  );
};

export default HealthSummary;
