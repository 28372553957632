import { generate } from '@ant-design/colors';

export const DEFAULT_BG = '#f0f2f5';
export const WHITE_BG = '#ffffff';
export const GREY = '#BFBFBF';
export const FONT = '#62778f';
export const MAIN_COLOR = '#00A2E0';
export const GREY_BORDER = '#f0f0f0';
export const palettes: string[] = generate(MAIN_COLOR);
export const BACKGROUND_GREY = '#E5E5E3';

export const WARNING_ICON = {
  BG: '#FF880033',
  COLOR: '#FF8800',
};

export const READ_ICON = {
  BG: '#E6FFFB',
  COLOR: '#13C2C2',
};

export const TABLE_ICON = {
  BG: '#FFF2E8',
  COLOR: '#FF7875',
};

export const BOOKED = {
  FONT: '#52C41A',
  BG: '#F6FFED',
  BORDER: '#B7EB8F',
  COLOR: '#95DE64',
};

export const ERROR_ALERT = {
  BACKGROUND: '#fff2f0',
  BORDER: '#ffccc7',
};

export const ATTENDED = {
  FONT: '#1890FF',
  BG: '#EBF9FF',
  BORDER: '#91D5FF',
};

export const DEFAULT = {
  FONT: '#555',
  BG: '#eee',
  BORDER: '#cfd9db',
};
