import { Layout, Space } from 'antd';
import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_BG, MAIN_COLOR } from '../../styles/color';
import { selectBookingClientData } from '../../redux/slices/booking';
import { CLIENT_DETAILS, STANDALONE_PATHS } from '../../constants/routes';
import { ClientData } from '../../model';
import useScrollToTop from '../../hooks/useScrollToTop';

const { Header, Content } = Layout;

const BookingHome: FC = () => {
  const client = useSelector(selectBookingClientData) as ClientData;
  const { pathname, search } = useLocation();

  useScrollToTop();

  if (isEmpty(client) && !STANDALONE_PATHS.includes(pathname)) {
    return <Navigate to={`${CLIENT_DETAILS}${search}`} />;
  }

  return (
    <Layout style={{
      position: 'absolute', minHeight: '100vh', width: '100%', backgroundColor: DEFAULT_BG,
    }}
    >
      <Header style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        backgroundColor: MAIN_COLOR,
      }}
      >
        <Space size={10}>
          <img
            src="/telecare-logo-white.svg"
            alt="Telecare Logo"
            width={150}
            style={{ paddingBottom: '10px' }}
          />
        </Space>
      </Header>
      <Content style={{ marginTop: 64, marginBottom: 62 }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default BookingHome;
