import React from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import enUSIntl from 'antd/lib/locale/en_US';

import RouterConfig from './navigation/RouterConfig';
import './App.less';

moment.locale('en-au');

function App() {
  return (
    <ConfigProvider locale={enUSIntl}>
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
