import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const AxiosInstance = () => axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': process.env.REACT_APP_API_KEY || '',
    'Site-Id': process.env.REACT_APP_CURRENT_SITE_ID || '',
  },
});

const axiosInstance = AxiosInstance();

export default axiosInstance;

const SimpleAxiosInstance = () => axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const simpleRequest = SimpleAxiosInstance();
