import {
  Col, Form, Input, Row, Select, Typography,
} from 'antd';
import React, { FC } from 'react';
import { STATES } from '../../constants/address';

const { Option } = Select;

const AddressFormSection: FC<{title: string, addressKey: string, isAllFieldsRequired?: boolean}> = ({ title, addressKey, isAllFieldsRequired }) => (
  <>
    <Typography.Title level={5}>
      {title}
    </Typography.Title>
    <Form.Item required={isAllFieldsRequired} label="Street Address" name={[addressKey, 'streetAddress']} rules={[{ required: isAllFieldsRequired, message: 'Please input your street address' }]}>
      <Input />
    </Form.Item>
    <Form.Item required={isAllFieldsRequired} label="Suburb or City" name={[addressKey, 'suburb']} rules={[{ required: isAllFieldsRequired, message: 'Please input your suburb or city' }]}>
      <Input placeholder="Please input suburb or town" />
    </Form.Item>
    <Row justify="space-between">
      <Col span={11}>
        <Form.Item label="State" required={isAllFieldsRequired} name={[addressKey, 'state']} rules={[{ required: isAllFieldsRequired, message: 'Please input your state' }]}>
          <Select
            placeholder="Please select state"
            style={{ width: '100%' }}
          >
            {STATES.map(({ value, label }) => (
              <Option value={value} key={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={11}>
        <Form.Item
          label="Postcode"
          name={[addressKey, 'postcode']}
          required={isAllFieldsRequired}
          rules={[
            { required: addressKey === 'addressResidential', message: 'Please input your postcode.' },
            {
              pattern: /^(\d{4})$/,
              message: 'Please input a valid postcode.',
            },
          ]}
        >
          <Input
            placeholder="Please input postcode"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default AddressFormSection;
