import styled from 'styled-components';
import { Modal, Col } from 'antd';

export const BookingModal = styled(Modal)`
  .ant-modal-content {
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
`;

export const ColWithBar = styled(Col)`
  height: 600px;
  overflow-y: scroll;
  margin-right: 27px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 16px;
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    background: #c1c1c1;
    height: 30px;
  }
`;
