import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { AppointmentType, BookingDataState, QuestionAndAnswers } from '../../model';

const initialState: BookingDataState = {
  appointment: {
    type: AppointmentType.NEW,
  },
};

export const bookingDataSlice = createSlice({
  name: 'bookingData',
  initialState,
  reducers: {
    updateBookingData: (state, action) => ({ ...state, ...action.payload }),
    updateBookingAppointmentData: (state, action) => {
      const { appointment } = state as BookingDataState;
      return { ...state, appointment: { ...appointment, ...action.payload } };
    },
    updateBookingClientData: (state, action) => {
      const { client } = state as BookingDataState;
      return { ...state, client: { ...client, ...action.payload } };
    },
    updateBookingMedicareCardData: (state, action) => {
      const { medicareCard } = state as BookingDataState;
      return { ...state, medicareCard: { ...medicareCard, ...action.payload } };
    },
    updateMetadata: (state, action) => {
      const { metadata } = state as BookingDataState;
      return { ...state, metadata: { ...metadata, ...action.payload } };
    },
    addBookingQuestionAndAnswersData: (state, action: PayloadAction<QuestionAndAnswers>) => {
      const { questionAndAnswers } = state as BookingDataState;
      return { ...state, questionAndAnswers: questionAndAnswers ? { ...questionAndAnswers, ...action.payload } : action.payload };
    },
    addAttachmentsData: (state, action) => {
      const { attachments } = state as BookingDataState;
      return { ...state, attachments: attachments ? { ...attachments, ...action.payload } : action.payload };
    },
    addConsentsData: (state, action) => {
      const { consents } = state as BookingDataState;
      return { ...state, consents: consents ? { ...consents, ...action.payload } : action.payload };
    },
    addEwayPaymentData: (state, action) => ({ ...state, ewayPayment: action.payload }),
    resetBookingData: () => initialState,
  },
});

export const selectBookingData = (state: RootState) => state.bookingData;
export const selectSpecialistId = (state: RootState) => state.bookingData.appointment?.specialistId;
export const selectBookingAppointmentData = (state: RootState) => {
  const { appointment } = state.bookingData;
  return appointment ?? {};
};
export const selectBookingClientData = (state: RootState) => {
  const { client } = state.bookingData;
  return client ?? {};
};

export const selectBookingMedicareCardData = (state: RootState) => {
  const { medicareCard } = state.bookingData;
  return medicareCard ?? {};
};

export const selectBookingQuestionAndAnswersData = (state: RootState) => {
  const { questionAndAnswers } = state.bookingData;
  return questionAndAnswers ?? {};
};

export const selectBookingAttachmentsData = (state: RootState) => {
  const { attachments } = state.bookingData;
  return attachments ?? {};
};

export default bookingDataSlice.reducer;
export const {
  updateBookingData,
  updateBookingAppointmentData,
  updateBookingClientData,
  updateBookingMedicareCardData,
  updateMetadata,
  addBookingQuestionAndAnswersData,
  addAttachmentsData,
  addConsentsData,
  addEwayPaymentData,
  resetBookingData,
} = bookingDataSlice.actions;
