export const BREAKPOINTS = {
  MOBILE_MAX_WIDTH: '768px',
  TABLET_MIN_WIDTH: '769px',
  TABLET_MAX_WIDTH: '1024px',
  DESKTOP_MIN_WIDTH: '1025px',
};

export const DEVICES_PREFIX = '@media screen and';

export const DEVICES = {
  mobile: `${DEVICES_PREFIX} (max-width: ${BREAKPOINTS.MOBILE_MAX_WIDTH})`,
  desktop: `${DEVICES_PREFIX} (min-width: ${BREAKPOINTS.TABLET_MIN_WIDTH})`,
};

export const QUERIES = {
  mobile: `(max-width: ${BREAKPOINTS.MOBILE_MAX_WIDTH})`,
  desktop: `(min-width: ${BREAKPOINTS.TABLET_MIN_WIDTH})`,
};

export const ANT_DESIGN_MOBILES = ['xs', 'sm'];
export const ANT_DESIGN_DESKTOPS = ['md', 'lg', 'xl', 'xll'];

type Screens = {
  [key: string]: boolean
};

export const checkIsMobile = (screens: Screens) => Object.entries(screens).some((screen: [string, boolean]) => {
  const [key, value] = screen;
  return value && ANT_DESIGN_MOBILES.includes(key);
});
