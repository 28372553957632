import React, {
  FC, useEffect, useMemo,
} from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import AvailableTime, { AvailableTimeForUI } from '../../model/AvailableTime';
import { DATE_WITH_DASH } from '../../constants/time-formats';
import Timeslot from '../Timeslot';
import { BookingTime } from '../../model';

const generateValidAvailableTimeList = (availableTimeList: AvailableTime[]): AvailableTimeForUI[] => {
  const today = moment().format(DATE_WITH_DASH);
  const tomorrow = moment().add(1, 'days').format(DATE_WITH_DASH);
  return availableTimeList.reduce((result: AvailableTimeForUI[], time) => {
    const { date } = time;
    const ref = React.createRef<HTMLDivElement>();
    if (date === today) {
      return [...result, { ...time, isToday: true, ref }];
    }
    if (date === tomorrow) {
      return [...result, { ...time, isToday: true, ref }];
    }
    if (date > today) {
      return [...result, { ...time, ref }];
    }
    return result;
  }, []);
};

type Props = {
  availableTimeList: AvailableTime[],
  aptLength: string,
  bookingTime: BookingTime,
  // eslint-disable-next-line no-unused-vars
  updateBookingTime: (data: BookingTime) => void,
}

const AvailableTimeslotList: FC<Props> = ({
  availableTimeList, aptLength, bookingTime, updateBookingTime,
}) => {
  const validAvailableTimeList: AvailableTimeForUI[] = useMemo<AvailableTimeForUI[]>(() => generateValidAvailableTimeList(availableTimeList), [availableTimeList]);

  useEffect(() => {
    const ref = validAvailableTimeList.find(({ date }) => moment(bookingTime.date).format(DATE_WITH_DASH) === date)?.ref;

    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [bookingTime.date, validAvailableTimeList]);

  if (validAvailableTimeList.length < 1) {
    return (
      <Typography.Title level={5} style={{ textAlign: 'center' }}>
        No available time at the moment
      </Typography.Title>
    );
  }

  return (
    <>
      {validAvailableTimeList.map((availableTime) => {
        const { isToday, date } = availableTime;
        return (
          <Timeslot
            aptLength={aptLength}
            key={date}
            availableTime={availableTime}
            bookingTime={bookingTime}
            updateBookingTime={updateBookingTime}
            titleStyle={isToday ? {} : { fontWeight: 'normal' }}
          />
        );
      })}
    </>
  );
};
export default AvailableTimeslotList;
