import React, { FC } from 'react';
import { Avatar, Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Speciality } from '../../../model/Speciality';
import { StyledCard, StyledPrimaryButton } from './style';
import { MAIN_COLOR, WHITE_BG } from '../../../styles/color';
import { DOCTORS_ROUTE } from '../../../constants/routes';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { updateBookingData } from '../../../redux/slices/booking';

type Props = {
  speciality: Speciality
}

const SpecialityProfileCard: FC<Props> = ({ speciality }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleBookNow = () => {
    dispatch(updateBookingData({ speciality }));
    navigate(DOCTORS_ROUTE, { state: speciality });
  };

  return (
    <StyledCard title={speciality.name} key={speciality.id}>
      <Card
        bordered={false}
        style={{
          height: '80px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {speciality.specialistCount > 0 ? (
          <Avatar.Group
            size="large"
            maxCount={5}
            maxStyle={{
              color: '#00A2E0',
              border: '1px solid #00A2E0',
              backgroundColor: '#fff',
            }}
          >
            {speciality.specialistAvatarLinks.map((link) => (
              <Avatar
                key={link}
                src={link}
                icon={<UserOutlined style={{ color: MAIN_COLOR }} />}
                size={40}
                style={{
                  border: `1px solid ${MAIN_COLOR}`,
                  background: WHITE_BG,
                }}
              />
            ))}
          </Avatar.Group>
        ) : (
          '0 specialists'
        )}
      </Card>
      <StyledPrimaryButton
        onClick={handleBookNow}
        disabled={speciality.specialistCount <= 0}
      >
        Select
      </StyledPrimaryButton>
    </StyledCard>
  );
};

export default SpecialityProfileCard;
