import { Typography } from 'antd';
import React from 'react';
import AgreeCheck from '../../../components/AgreeCheck';

interface FeesAndCancellationPolicyProps {
  isAgreedTheFees: boolean;
  isAgreedTheCancelPolicy: boolean;
  // eslint-disable-next-line no-unused-vars
  setIsAgreedTheFees: (isAgreedTheFees: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setIsAgreedTheCancelPolicy: (isAgreedTheCancelPolicy: boolean) => void;
}

const FeesAndCancellationPolicy = ({
  isAgreedTheFees, isAgreedTheCancelPolicy, setIsAgreedTheFees, setIsAgreedTheCancelPolicy,
}: FeesAndCancellationPolicyProps) => (
  <div style={{ marginTop: '50px' }}>
    <Typography.Title
      level={2}
      style={{ marginBottom: '50px', textAlign: 'center' }}
    >
      Fees and Cancellation Policy
    </Typography.Title>

    <AgreeCheck
      text={['I have read and accepted the fees advertised on Telecare\'s website. I understand that Telecare will collect my card details for payment via eway, a secure payment portal. I agree to pay the full fee owing immediately following my consult. I consent to Telecare to use my collected card details for payment of any follow up consults I book and attend.']}
      isAgreed={isAgreedTheFees}
      setIsAgreed={setIsAgreedTheFees}
    />

    <AgreeCheck
      text={(
        <>
          Our doctors have reserved their time for you. If you must cancel your consultation, we respectfully request 48 hours notice so we have time to fill your spot.
          <br />
          Missed consultations, or consultations cancelled without valid medical reason within 48 hours of the consultation, will incur a fee of
          <strong>{' $150'}</strong>
          .
        </>
      )}
      isAgreed={isAgreedTheCancelPolicy}
      setIsAgreed={setIsAgreedTheCancelPolicy}
    />
  </div>
);

export default FeesAndCancellationPolicy;
