import {
  Col, DatePicker, Form, FormInstance, Input, Row, Switch, Typography,
} from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isNil } from 'lodash';

import { useSelector } from 'react-redux';

import moment from 'moment';
import { DATE_WITH_DASH, TABLE_TIME } from '../../../constants/time-formats';
import { selectBookingClientData } from '../../../redux/slices/booking';
import { FormContainer } from '../../../components/Container';
import AddressFormSection from '../../../components/AddressFormSection';
import { ClientData } from '../../../model';
import { mobileRegxPattern, trimPhoneNumber } from '../../../utils/phone-number';

const ClientDetailsForm: FC<{form: FormInstance}> = ({ form }) => {
  const clientData = useSelector(selectBookingClientData);
  const [hasDiffPostal, setHasDiffPostal] = useState(false);

  useEffect(() => {
    const { dateOfBirth, ...otherClientData } = clientData as ClientData;
    if (!isNil(clientData)) {
      form.setFieldsValue({ ...otherClientData });
      if (dateOfBirth) {
        form.setFieldsValue({
          dateOfBirth: moment(dateOfBirth, DATE_WITH_DASH),
        });
      }
    }
  }, [clientData, form]);

  const handleDifferentPostalAddress = (value: boolean) => {
    setHasDiffPostal(value);
  };

  return (
    <>
      <FormContainer layout="vertical" form={form} name="clientDetails" scrollToFirstError style={{ marginBottom: '50px' }}>
        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              label="First Name"
              required
              name="firstName"
              rules={[{ required: true, message: 'Please input your first name.' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              name="lastName"
              label="Last Name"
              required
              rules={[{ required: true, message: 'Please input your last name.' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              name="dateOfBirth"
              label="Date of Birth"
              required
              rules={[
                {
                  type: 'object' as const,
                  required: true,
                  message: 'Please select time.',
                },
              ]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder="DD/MM/YYYY"
                format={TABLE_TIME}
              />
            </Form.Item>
          </Col>

          <Col span={11}>
            <Form.Item
              name="mobile"
              label="Mobile Number"
              required
              rules={[
                { required: true, message: 'Please input your mobile number.' },
                {
                  transform: trimPhoneNumber,
                  pattern: mobileRegxPattern,
                  message: 'Please input a valid mobile number.',
                },
              ]}
            >
              <Input placeholder="04** *** ***" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="email"
          label="Email"
          required
          rules={[
            { required: true, message: 'Please input your email.' },
            { type: 'email', message: 'Please input a valid email address.' },
          ]}
        >
          <Input />
        </Form.Item>

        <AddressFormSection title="Residential Address" addressKey="addressResidential" isAllFieldsRequired />
        <Form.Item label="My residential address is not the same as my postal address" name="diffPostalAddress" initialValue={false}>
          <Switch onChange={handleDifferentPostalAddress} />
        </Form.Item>
        {hasDiffPostal && <AddressFormSection title="Postal Address" addressKey="addressPostal" />}

        <Typography.Title level={5}>
          Next of Kin
        </Typography.Title>

        <Form.Item
          label="Name"
          name={['nextOfKin', 'name']}
        >
          <Input />
        </Form.Item>

        <Row justify="space-between">
          <Col span={11}>
            <Form.Item
              name={['nextOfKin', 'mobile']}
              label="Mobile Number"
              rules={[
                {
                  transform: trimPhoneNumber,
                  pattern: mobileRegxPattern,
                  message: 'Please input a valid phone number.',
                },
              ]}
            >
              <Input placeholder="04** *** ***" />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Relationship to You"
              name={['nextOfKin', 'relationshipToYou']}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </FormContainer>
    </>
  );
};

export default ClientDetailsForm;
