import firebase from '../utils/firebase';

class Analytics {
  // eslint-disable-next-line class-methods-use-this
  logEvent(name: string, parameters: any) {
    firebase.analytics.logEvent(name, {
      site: 'patient-booking',
      ...parameters,
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'local') {
      // eslint-disable-next-line no-console
      console.log('Event Tracking: ', name, {
        site: 'website-booking',
        ...parameters,
      });
    }
  }

  logPageViewEvent(pageName: string, parameters: any) {
    this.logEvent('page_view', {
      page_name: pageName,
      ...parameters,
    });
  }
}

const analytics = new Analytics();
export default analytics;
