export const CONSENTS_MAPPING = {
  isAgreedTheFees: {
    name: 'Fees and Cancellation Policy',
    description: 'I have read and accepted the fees advertised on Telecare\'s website. I understand that Telecare will collect my card details for payment via eway, a secure payment portal. I agree to pay the full fee owing immediately following my consult. I consent to Telecare to use my collected card details for payment of any follow up consults I book and attend.',
  },
  isAgreedTheCancelPolicy: {
    name: 'Fees and Cancellation Policy',
    description: 'Missed consultations, or consultations cancelled without valid medical reason within 48 hours of the consultation, will incur a fee of $150.',
  },
  isAgreedThePrivacyPolicy: {
    name: 'Consents',
    description: 'I have read Telecare\'s Privacy Policy and I understand that the information submitted in this form will be accessed by:\n'
      + ' - Telecare\'s practice management and reception team\n'
      + ' - Telecare\'s team of clinicians to ensure I receive optimal care',
  },
};
