import React, { FC, useEffect } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SpecialistList from '../../components/SpecialistList';
import { useGetSpecialistsQuery } from '../../services/specialists';
import { DEVICES } from '../../styles/breakpoint';
import { selectBookingData } from '../../redux/slices/booking';
import analytics from '../../infra/analytics';
import {
  PAGE_VIEW_EVENT, SELECT_SPECIALIST, SITE_NAME,
} from '../../constants/analytics';

const SpecialistsContainer = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 98%;
  
  ${DEVICES.desktop} {
    width: 80%;
    margin: 50px auto 100px;
  }
`;

const Specialists: FC = () => {
  const { speciality } = useSelector(selectBookingData);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const specialtyId = searchParams.get('specialty') || '';
  const specialistId = searchParams.get('specialist');
  const { data: specialists, isLoading } = useGetSpecialistsQuery(speciality?.id || specialtyId);
  let specialistNew = specialists;
  if (specialistId) {
    specialistNew = specialists?.filter((specialist) => specialist.id === specialistId);
  }
  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: SELECT_SPECIALIST,
    });
  }, []);

  return (
    <SpecialistsContainer>
      <Spin tip="Please wait while we bring up each specialist's availabilities..." spinning={isLoading}>
        {specialistNew && <SpecialistList specialists={specialistNew} />}
      </Spin>
    </SpecialistsContainer>
  );
};

export default Specialists;
