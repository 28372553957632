import React, { FC, useEffect, useState } from 'react';
import {
  Typography, Spin, Alert,
} from 'antd';
import styled from 'styled-components';
import { DEVICES } from '../../styles/breakpoint';
import { getSpecialities } from '../../services/specialities';
import { Speciality } from '../../model/Speciality';
import SpecialitiesGrid from './SpecialitiesGrid';
import FooterNavigator from '../../components/FooterNavigator';
import { CLIENT_DETAILS } from '../../constants/routes';
import analytics from '../../infra/analytics';
import {
  PAGE_VIEW_EVENT, SELECT_SPECIALTY, SITE_NAME,
} from '../../constants/analytics';

type Props = {}

const SpecialitiesContainer = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 98%;
  
  ${DEVICES.desktop} {
    width: 80%;
    margin: 50px auto 100px;
  }
`;

const Specialities: FC<Props> = () => {
  const [specialties, setSpecialities] = useState<Speciality[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: SELECT_SPECIALTY,
    });
  }, []);

  useEffect(() => {
    const fetchSpecialities = async () => {
      setIsLoading(true);
      try {
        const { specialities = [] } = await getSpecialities();
        setSpecialities(specialities);
      } catch {
        setErrorMessage('Something went wrong, please try again later or contact Telecare at (03 9052 4872)');
      } finally {
        setIsLoading(false);
      }
    };
    fetchSpecialities();
  }, []);

  return (
    <SpecialitiesContainer>
      <Typography.Title
        level={2}
        style={{ marginBottom: '50px' }}
      >
        Select a Specialty
      </Typography.Title>
      {errorMessage && <Alert type="error" message={errorMessage} closable />}
      {isLoading ? (
        <Spin tip="Please wait while we bring up our specialties..." />
      ) : (
        <>
          <SpecialitiesGrid specialities={specialties} />
          <FooterNavigator
            error=""
            back={CLIENT_DETAILS}
            hasNext={false}
            containerStyle={{ marginTop: '40px' }}
          />
        </>
      )}
    </SpecialitiesContainer>
  );
};

export default Specialities;
