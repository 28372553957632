import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

import { Logo, MainSection, TextSection } from './styles';

const Container = styled.section`
  padding: 45px 10px;
  text-align: center;
`;

const { Title } = Typography;

const Error = () => (
  <Container>
    <header>
      <Logo alt="logo" width="15%" height="68px" src="/telecare-logo.png" />
    </header>
    <MainSection>
      <Title level={2} style={{ marginBottom: '50px' }}>
        Oops! Something went wrong...
      </Title>
    </MainSection>
    <TextSection style={{ marginBottom: '50px' }}>
      <Title level={4} style={{ fontWeight: 'normal' }}>
        Please contact Telecare at (03 9052 4872).
      </Title>
    </TextSection>
  </Container>
);

export default Error;
