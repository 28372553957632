import { AutoComplete, Input } from 'antd';
import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Speciality } from '../../../model/Speciality';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { updateBookingData } from '../../../redux/slices/booking';
import { DOCTORS_ROUTE } from '../../../constants/routes';

const StyledSearchBox = styled(AutoComplete)`
  margin: 50px auto 80px;
  width: 50%;
  display: block;
`;

type Props = {
  specialities: Speciality[]
}

type SpecialityOption = {
  label: string;
  value: string;
  specialistCount: number;
};

const SpecialitySearchBox: FC<Props> = ({ specialities }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const specialityOptions = useMemo(() => specialities.map(({ name, specialistCount }) => ({ label: name, value: name.toLowerCase(), specialistCount })), [specialities]);
  const [selectedSpecialities, setSelectedSpecialities] = useState<SpecialityOption[]>([]);

  useEffect(() => {
    if (specialityOptions.length > 0) {
      setSelectedSpecialities(specialityOptions.filter(({ specialistCount }) => specialistCount > 0));
    }
  }, [specialityOptions]);

  const handleSearch = useCallback((input) => {
    const inputStr = input.trim().toLowerCase();
    setSelectedSpecialities(specialityOptions.filter(({ value, specialistCount }) => value.startsWith(inputStr) && specialistCount > 0));
  }, [specialityOptions]);

  const handleSelect = useCallback((value) => {
    const speciality = specialities.find(({ name }) => name.toLowerCase() === value);
    if (speciality) {
      dispatch(updateBookingData({ speciality }));
      navigate(DOCTORS_ROUTE, { state: speciality });
    }
  }, [dispatch, navigate, specialities]);

  return (
    <StyledSearchBox
      dropdownMatchSelectWidth={252}
      style={{ width: 300 }}
      options={selectedSpecialities}
      onSearch={handleSearch}
      onSelect={handleSelect}
    >
      <Input.Search size="large" placeholder="search for a speciality" enterButton />
    </StyledSearchBox>
  );
};

export default SpecialitySearchBox;
