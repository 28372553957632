import styled from 'styled-components';
import {
  GREY_BORDER, MAIN_COLOR, WHITE_BG,
} from '../../styles/color';

export const AvailableTimeslotsContainer = styled.div`
  padding: 15px 0 25px;
  border-top: 1px solid ${GREY_BORDER};
`;

export const TimeTag = styled.div<{isSelected: boolean}>`
  vertical-align: middle;
  line-height: 32px;
  padding: 0 6px;
  height: 32px;
  text-align: center;
  background: ${({ isSelected }) => (isSelected ? MAIN_COLOR : WHITE_BG)};
  border: ${({ isSelected }) => (isSelected ? 'none' : '1px solid #d9d9d9')};
  ${({ isSelected }) => (isSelected ? 'color: white;' : '')}
  border-radius: 40px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 12px;
  cursor: pointer;
`;
