import React, { FC } from 'react';
import { List, Typography } from 'antd';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Specialists } from '../../model';
import SpecialistCard from '../SpecialistCard';
import { MAIN_COLOR, WHITE_BG } from '../../styles/color';
import { DEVICES } from '../../styles/breakpoint';
import FooterNavigator from '../FooterNavigator';
import { CLIENT_DETAILS, SPECIALITIES_ROUTE } from '../../constants/routes';

type Props = {
    specialists: Specialists,
};

const { Item } = List;

export const ItemContainer = styled(Item)`
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-top: 20px solid ${MAIN_COLOR};
    padding: 5px 10px 0;
    overflow: scroll;
    background: ${WHITE_BG};
    margin-bottom: 30px;

    ${DEVICES.desktop} {
        padding: 18px 55px 0;
    }
`;

const SpecialistList: FC<Props> = ({ specialists }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const backRoute = searchParams.get('specialist') && searchParams.get('specialty') ? CLIENT_DETAILS : SPECIALITIES_ROUTE;
  return (
    <div>
      <Typography.Title level={2} style={{ marginBottom: '50px' }}>
        Select a Specialist
      </Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={specialists}
        renderItem={(specialist) => (
          <ItemContainer>
            <SpecialistCard specialist={specialist} key={specialist.id} />
          </ItemContainer>
        )}
      />
      <FooterNavigator
        error=""
        back={backRoute}
        hasNext={false}
        containerStyle={{ marginTop: '40px' }}
      />
    </div>
  );
};

export default SpecialistList;
