import {
  DatePicker, Form, Input, Radio, Row, Tooltip, Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import moment from 'moment';
import { FormContainer } from '../../../components/Container';
import { DATE_WITH_DASH } from '../../../constants/time-formats';
import FooterNavigator from '../../../components/FooterNavigator';
import { CLIENT_CREDIT_CARD_DETAILS, DOCUMENTS_UPLOADING } from '../../../constants/routes';
import {
  addBookingQuestionAndAnswersData,
  selectBookingMedicareCardData,
  selectBookingQuestionAndAnswersData,
  updateBookingMedicareCardData,
} from '../../../redux/slices/booking';
import { useAppDispatch } from '../../../hooks/redux-hooks';
import { MedicareCard } from '../../../model/MedicareCard';
import { DVACardType } from '../../../model/DVACardType';
import { HealthSummaryQuestionMapping } from '../../../model';
import './index.css';

const MedicareForm = () => {
  const [form] = Form.useForm();
  const medicareCardData = useSelector(selectBookingMedicareCardData);
  const questionAndAnswersData = useSelector(selectBookingQuestionAndAnswersData);
  const dispatch = useAppDispatch();
  const [isDisplayDVASection, setIsDisplayDVASection] = useState<boolean>(false);

  const submit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const {
        expiry, dva, ...otherValues
      } = values;

      dispatch(updateBookingMedicareCardData({
        ...otherValues,
        dva: dva?.hasDept ? dva : undefined,
        expiry: expiry.endOf('month').format(DATE_WITH_DASH),
      }));

      dispatch(addBookingQuestionAndAnswersData({
        ...questionAndAnswersData,
        hasDept: dva?.hasDept ? 'YES' : 'NO',
      }));

      return true;
    } catch (errorInfo) {
      return false;
    }
  }, [questionAndAnswersData, form, dispatch]);

  useEffect(() => {
    if (!isEmpty(medicareCardData)) {
      const { expiry, dva, ...otherData } = medicareCardData as MedicareCard;
      form.setFieldsValue({
        ...otherData,
        dva,
        expiry: moment(expiry, DATE_WITH_DASH),
      });
      setIsDisplayDVASection(dva?.hasDept === true);
    }
  }, [questionAndAnswersData, medicareCardData, form]);

  const handleFormValueChange = useCallback((changedValues) => {
    const { dva } = changedValues;
    if (dva && dva.hasDept !== undefined) {
      setIsDisplayDVASection(dva.hasDept === true);
    }
  }, []);

  return (
    <>
      <FormContainer layout="vertical" form={form} onValuesChange={handleFormValueChange}>
        <Form.Item
          label="Medicare Card Number"
          required
          name="medicareCardNumber"
          rules={[
            {
              required: true,
              message: 'Please input your Medicare card number.',
            },
            {
              pattern: /^(\d{10})$/,
              message: 'Please input a valid Medicare card number.',
            },
          ]}
        >
          <Input placeholder="*********" />
        </Form.Item>
        <Form.Item
          label={(
            <>
              Medicare IRN (individual reference number)
              <Tooltip
                overlayClassName="tooltips-card"
                placement="right"
                title={
                  <img alt="IRN example" src={`${process.env.PUBLIC_URL}/IRN.png`} />
                }
              >
                <InfoCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            </>
          )}
          required
          name="medicareCardIRN"
          rules={[
            { required: true, message: 'Please input your Medicare card IRN.' },
            {
              pattern: /^(\d)$/,
              message: 'Please input a valid Medicare card IRN.',
            },
          ]}
        >

          <Input placeholder="*" />
        </Form.Item>
        <Form.Item
          label="Expiry"
          required
          name="expiry"
          rules={[{ required: true, message: 'Please input your Medicare card number\'s expiry date.' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            placeholder="MM/YYYY"
            format="MM/YYYY"
            picker="month"
          />
        </Form.Item>
        <Form.Item
          required
          rules={[
            {
              required: true,
              message: 'Please answer this question.',
            },
          ]}
          name={['dva', 'hasDept']}
          label={HealthSummaryQuestionMapping.hasDept}
        >
          <Radio.Group>
            <Radio value>YES</Radio>
            <Radio value={false}>NO</Radio>
          </Radio.Group>
        </Form.Item>

        {isDisplayDVASection && (
        <>
          <Typography.Title level={5}>
            DVA Card
          </Typography.Title>

          <Row justify="space-between">
            <Form.Item
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your dva card type.',
                },
              ]}
              name={['dva', 'cardType']}
              label="DVA Card Type"
            >
              <Radio.Group>
                <Radio value={DVACardType.GOLD}>Gold</Radio>
                <Radio value={DVACardType.WHITE}>White</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              style={{ width: '50%' }}
              required
              rules={[
                {
                  required: true,
                  message: 'Please input your dva card number.',
                },
                {
                  pattern: /^[N,V,Q,S,W,T]([A-Z ][0-9]{1,6}|[A-Z]{2}[0-9]{1,5}|[A-Z]{3}[0-9]{1,4})[A-Z]?$/,
                  message: 'Please input a valid DVA card number.',
                },
              ]}
              name={['dva', 'cardNumber']}
              label="DVA No."
            >
              <Input />
            </Form.Item>
          </Row>
        </>
        )}

        <FooterNavigator error="" submit={submit} next={CLIENT_CREDIT_CARD_DETAILS} back={DOCUMENTS_UPLOADING} />
      </FormContainer>
    </>
  );
};

export default MedicareForm;
