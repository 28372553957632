import React, { FC, useEffect, useState } from 'react';
import { Form, Typography } from 'antd';
import { ContainerWithForm } from '../../components/Container';
import EwayPaymentForm from './EwayPaymentForm';
import FeesAndCancellationPolicy from './FeesAndCancellationPolicy';
import FooterNavigator from '../../components/FooterNavigator';
import { CLIENT_MEDICARE_CARD_DETAILS, FINISH } from '../../constants/routes';
import { createTokenCustomerOnEway, fetchTokenCustomerCreationResult } from '../../services/payment';
import { RequestStatus } from '../../model';
import { addConsentsData, addEwayPaymentData } from '../../redux/slices/booking';
import { CONSENTS_MAPPING } from '../../constants/consents';
import { useAppDispatch } from '../../hooks/redux-hooks';
import analytics from '../../infra/analytics';
import {
  CREDIT_CARD_AND_POLICY,
  PAGE_VIEW_EVENT,
  SITE_NAME,
} from '../../constants/analytics';

const CreditCardDetails: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [eWayAccessCode, setEWayAccessCode] = useState('');
  const [eWayFormActionURL, setEWayFormActionURL] = useState('');

  const [isAgreedTheFees, setIsAgreedTheFees] = useState(false);
  const [isAgreedTheCancelPolicy, setIsAgreedTheCancelPolicy] = useState(false);

  useEffect(() => {
    analytics.logPageViewEvent(PAGE_VIEW_EVENT, {
      site: SITE_NAME,
      page_name: CREDIT_CARD_AND_POLICY,
    });
  }, []);

  const submit = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    if (!isAgreedTheFees || !isAgreedTheCancelPolicy) {
      setErrorMessage(`Please agree to the ${!isAgreedTheCancelPolicy ? 'cancellation policy' : 'fees policy'} before continuing.`);
      setIsLoading(false);
      return false;
    }

    try {
      dispatch(addConsentsData({
        isAgreedTheFees: { ...CONSENTS_MAPPING.isAgreedTheFees, agreed: true },
        isAgreedTheCancelPolicy: { ...CONSENTS_MAPPING.isAgreedTheCancelPolicy, agreed: true },
      }));
      const values = await form.validateFields();
      const { EWAY_CARDEXPIRYDATE, ...finalValues } = values;
      await createTokenCustomerOnEway(eWayFormActionURL, {
        ...finalValues,
        EWAY_ACCESSCODE: eWayAccessCode,
        EWAY_PAYMENTTYPE: 'Credit Card',
        EWAY_CARDEXPIRYMONTH: parseInt(EWAY_CARDEXPIRYDATE.substring(0, 2), 10),
        EWAY_CARDEXPIRYYEAR: parseInt(EWAY_CARDEXPIRYDATE.substring(3, 7), 10),
      });
      const { status, data } = await fetchTokenCustomerCreationResult(eWayAccessCode);
      if (status === RequestStatus.SUCCESS) {
        dispatch(addEwayPaymentData({
          customerId: data.tokenCustomerID,
        }));
        return true;
      }
      setErrorMessage('Setup payment method failed. Please try again later or contact Telecare at (03 9052 4872)');
      return false;
    } catch (e) {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContainerWithForm>
      <Typography.Title
        level={2}
      >
        Payment Consent and Cancellation Policy
      </Typography.Title>
      <Typography.Paragraph strong style={{ marginBottom: '0' }}>
        To proceed with a telehealth appointment, Telecare will need to collect credit details from you.
      </Typography.Paragraph>
      <Typography.Paragraph strong style={{ marginBottom: '50px' }}>
        You will not be charged unless an appointment takes place or if you do not meet our cancellation policy below.
      </Typography.Paragraph>

      <EwayPaymentForm form={form} setEWayAccessCode={setEWayAccessCode} setEWayFormActionURL={setEWayFormActionURL} />

      <div id="eWAYBlock">
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <a href="https://www.eway.com.au/developers/nz/secure-site-seal?i=12&amp;se=3&amp;theme=0" title="eWAY Payment Gateway" target="_blank" rel="nofollow noreferrer">
            <img alt="eWAY Payment Gateway" src="https://www.eway.com.au/developers/developer/payment-code/verified-seal.php?img=12&amp;size=3&amp;theme=0" />
          </a>
        </div>
      </div>

      <FeesAndCancellationPolicy
        setIsAgreedTheFees={setIsAgreedTheFees}
        setIsAgreedTheCancelPolicy={setIsAgreedTheCancelPolicy}
        isAgreedTheFees={isAgreedTheFees}
        isAgreedTheCancelPolicy={isAgreedTheCancelPolicy}
      />

      {errorMessage && <Typography.Paragraph type="danger">{errorMessage}</Typography.Paragraph>}
      <FooterNavigator submit={submit} error="" next={FINISH} back={CLIENT_MEDICARE_CARD_DETAILS} containerStyle={{ marginTop: '30px' }} loading={isLoading} />
    </ContainerWithForm>
  );
};

export default CreditCardDetails;
